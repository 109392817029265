import { InquiryDraftView } from '@zola/svc-marketplace-ts-types';

import { AppThunk } from '~/reducers';
import { MappedInquiryView } from '~/types/mappedResponseTypes';
import Logger from '~/util/logger';

import ApiService from '../util/apiService';
import {
  receivedLastInquiryToStorefront,
  receivedDraft,
  deletedDraft,
} from './types/couplesInquiriesActionTypes';

export const requestLastInquiryToStorefront = (
  storefrontUuid: string
): AppThunk<Promise<MappedInquiryView | null>> => {
  return (dispatch) => {
    return ApiService.get<MappedInquiryView | null>(
      `/web-marketplace-api/v1/inquiry/get-last?storefront_uuid=${storefrontUuid}`
    ).then((inquiry) => {
      if (inquiry) {
        dispatch(receivedLastInquiryToStorefront(inquiry));
      }
      return inquiry;
    });
  };
};

export const createOrUpdateDraft = (
  storefrontUuid: string,
  currentStep: string
): AppThunk<Promise<void>> => {
  return (dispatch) => {
    return ApiService.post<InquiryDraftView>(`/web-marketplace-api/v1/inquiry/draft`, {
      storefrontUuid,
      currentStep,
      source: 'WEB',
    })
      .then((draft) => {
        dispatch(
          receivedDraft({
            storefrontUuid,
            draftUuid: draft.uuid,
          })
        );
      })
      .catch(Logger.error);
  };
};

export const deleteDraftByStorefront = (storefrontUuid?: string): AppThunk<Promise<void>> => {
  return (dispatch, getState) => {
    if (storefrontUuid) {
      const slice = getState().couplesInquiries;
      const draftMarkedForDeletion = slice.draftUuidToDelete;
      const draftForStorefront = slice.draftsByStorefrontUuid[storefrontUuid];

      if (draftMarkedForDeletion === draftForStorefront) {
        return ApiService.delete<InquiryDraftView>(
          `/web-marketplace-api/v1/inquiry/draft/${draftMarkedForDeletion}`
        )
          .then((draft) => {
            dispatch(
              deletedDraft({
                storefrontUuid,
                draftUuid: draft.uuid,
              })
            );
          })
          .catch(Logger.error);
      }
    }

    return Promise.resolve();
  };
};
