import { CtaType } from '@zola-helpers/client/dist/es/tracking/ctaEvents/ctaEvents';

export enum LINK_SHARING_CHANNELS {
  URL = 'URL',
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK',
}

export const LINK_SHARING_CHANNELS_CTA_ID = {
  [LINK_SHARING_CHANNELS.URL]: 'COPY_URL',
  [LINK_SHARING_CHANNELS.EMAIL]: 'SHARE_EMAIL_LINK',
  [LINK_SHARING_CHANNELS.FACEBOOK]: 'SHARE_FACEBOOK_LINK',
};

export const LINK_SHARING_CTA_TYPES = {
  [LINK_SHARING_CHANNELS.URL]: CtaType.BUTTON,
  [LINK_SHARING_CHANNELS.EMAIL]: CtaType.LINK,
  [LINK_SHARING_CHANNELS.FACEBOOK]: CtaType.LINK,
};
