import { RefObject, useCallback } from 'react';

import { MarketplaceInquiryFlowEntryPoint } from '@zola-helpers/client/dist/es/tracking/flow/flowEvents';

import { deleteDraftByStorefront } from '~/actions/couplesInquiriesActions';
import { useModal } from '~/contexts/ModalContext';
import { AppDispatch } from '~/reducers';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import {
  CtaType,
  getEntryPointByProductLocation,
  trackCtaClicked,
  TrackingProductLocation,
} from '~/util/trackingHelper';

import type { InquiryModalProps } from './InquiryModal';
import { InquiryFormValues } from './types';

export const useInquiryModal = () => {
  const { showV2PredefinedModal } = useModal();

  const showInquiryModal = useCallback(
    ({
      dispatch,
      trackingLocation,
      storefront,
      firstMoveUuid,
      reopenSourceModal,
      triggerRef = null,
      onSuccess,
      partialValues,
      onClose,
      entryPoint,
    }: {
      dispatch: AppDispatch;
      trackingLocation: TrackingProductLocation;
      storefront: CouplesStorefrontDetails;
      firstMoveUuid?: string;
      reopenSourceModal?: () => void;
      triggerRef?: RefObject<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement> | null;
      onSuccess?: () => void;
      partialValues?: Partial<InquiryFormValues>;
      onClose?: () => void;
      entryPoint?: MarketplaceInquiryFlowEntryPoint;
    }) => {
      const partialModalOptions = {
        triggerRef,
        dialogHeadingId: 'inquiry-modal-header',
        showCloseButton: true,
        lockBgScrolling: true,
      };

      const cleanupAfterInquiry = () => {
        window.history.replaceState({}, '', window.location.pathname);

        // This is a bit sneaky, it doesn't _always_ delete the draft, it only deletes
        // the draft if it was marked for deletion.
        return dispatch(deleteDraftByStorefront(storefront.uuid)).then(() => undefined);
      };

      const nonFirstMovesEntryPoint =
        entryPoint || getEntryPointByProductLocation(trackingLocation);

      showV2PredefinedModal<InquiryModalProps>(
        'INQUIRY_MODAL',
        {
          ...partialModalOptions,
          closeOnEscape: false,
          closeOnOverlayClick: false,
          disableFocusTrap: true,
          onAfterClose: () => {
            // Keep in mind, this is only invoked when the inquiry modal is canceld
            // out of with the X click in the top right
            if (reopenSourceModal) {
              reopenSourceModal();
            }
            if (onClose) {
              onClose();
            }
            cleanupAfterInquiry().catch(() => null);
          },
        },
        {
          reopenSourceModal,
          onClose: cleanupAfterInquiry,
          entryPoint: firstMoveUuid ? 'FIRST_MOVES' : nonFirstMovesEntryPoint,
          storefront,
          firstMoveUuid,
          onSuccess,
          partialValues,
        }
      );
    },
    [showV2PredefinedModal]
  );

  const trackAndShowInquiry = useCallback(
    ({
      ctaText,
      dispatch,
      firstMoveUuid,
      location,
      position,
      section,
      storefront,
      partialValues,
      triggerRef = null,
      onClose,
      entryPoint,
    }: {
      ctaText: string;
      dispatch: AppDispatch;
      firstMoveUuid?: string;
      location: TrackingProductLocation;
      position: number;
      section: string;
      storefront: CouplesStorefrontDetails;
      partialValues?: Partial<InquiryFormValues>;
      triggerRef?: RefObject<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement> | null;
      onClose?: () => void;
      entryPoint?: MarketplaceInquiryFlowEntryPoint;
    }) => {
      const cta = {
        ctaId: ctaText,
        // the link clicked, but we don't really have a link, we have a modal.
        cta: '/inquiry-modal',
        location,
        ctaType: CtaType.BUTTON,
        position,
        section,
        storefrontUuid: storefront.uuid,
      };
      trackCtaClicked(cta);
      showInquiryModal({
        dispatch,
        trackingLocation: location,
        storefront,
        triggerRef,
        firstMoveUuid,
        partialValues,
        onClose,
        entryPoint,
      });
    },
    [showInquiryModal]
  );

  return { showInquiryModal, trackAndShowInquiry };
};
