// images & icons
import FavoriteHeart from '@zola/zola-ui/src/assets/images/favorites/heart-full-no-outline.svg';

// styling
import styles from './ModalHeader.module.less';

interface ModalHeaderProps {
  image?: string;
}

export const ModalHeader = ({ image }: ModalHeaderProps) => (
  <div className={styles.modalHeaderContainer} data-testid="ModalHeader">
    <div className={styles.background} />

    {image && (
      <div className={styles.imageContainer}>
        <div className={styles.imageRelativeContainer}>
          <img src={FavoriteHeart} alt="" className={styles.leftHeart} />
          <img src={FavoriteHeart} alt="" className={styles.rightHeart} />
          <img src={image} alt="" className={styles.image} />
        </div>
      </div>
    )}
  </div>
);

export default ModalHeader;
