import { getVendorWordPlural } from '@zola-helpers/client/dist/es/marketplace/vendorWords';
import * as toastsActions from '@zola/zola-ui/src/actions/toastsV2Actions';
import CircleIcon from '@zola/zola-ui/src/components/CircleIcon/CircleIcon';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
// images and icons
import { LinkIcon } from '@zola/zola-ui/src/components/SvgIcons/Link';
import { MailIcon } from '@zola/zola-ui/src/components/SvgIcons/Mail';

import _capitalize from 'lodash/capitalize';

import FacebookSVG from '~/assets/images/social/facebook.svg';
import { useAppDispatch } from '~/reducers';
import { StandardLogFn } from '~/util/logger';
import { trackCtaClicked, CtaClickedEvent } from '~/util/trackingHelper';

import {
  LINK_SHARING_CHANNELS,
  LINK_SHARING_CHANNELS_CTA_ID,
  LINK_SHARING_CTA_TYPES,
} from '../../types/linkSharingChannels';

// styling
import styles from './LinksList.module.less';

const LINK_CHANNELS = {
  [LINK_SHARING_CHANNELS.URL]: {
    text: 'Copy URL',
    icon: <LinkIcon width={24} height={24} />,
  },
  [LINK_SHARING_CHANNELS.EMAIL]: {
    text: 'Share to email',
    icon: <MailIcon width={24} height={24} />,
  },
  [LINK_SHARING_CHANNELS.FACEBOOK]: {
    text: 'Share to Facebook',
    icon: <CircleIcon image={FacebookSVG} variant="transparent" size={24} alt="" />,
  },
};

interface LinksListProps {
  url: string;
  channels: LINK_SHARING_CHANNELS[];
  trackingProps?: CtaClickedEvent;
  vendorType?: string;
  name?: string;
  location?: string;
  shareType?: string;
}

const LinksList = ({
  url,
  channels,
  trackingProps,
  vendorType,
  name,
  location,
  shareType,
}: LinksListProps): JSX.Element | null => {
  const dispatch = useAppDispatch();

  const linkOnClick = (linkType: LINK_SHARING_CHANNELS) => {
    if (!linkType) return;

    // Tracking event: "CTA Clicked - Share Method"
    trackCtaClicked({
      ...trackingProps,
      ctaType: LINK_SHARING_CTA_TYPES[linkType],
      ctaId: LINK_SHARING_CHANNELS_CTA_ID[linkType],
    });

    switch (linkType) {
      case LINK_SHARING_CHANNELS.URL:
        navigator.clipboard
          .writeText(url)
          .then(() => {
            dispatch(toastsActions.positive({ headline: 'Copied to clipboard!' }));
          })
          .catch((e) => StandardLogFn(e));
        break;

      case LINK_SHARING_CHANNELS.EMAIL: {
        const subject = 'Wedding inspo from Zola';
        const storefrontBody = `Hi, I found a great vendor on Zola!

${name}, ${location} ${_capitalize(getVendorWordPlural(vendorType))}

${url}
        `;
        const body = `Hey there!
I found this on Zola and thought of you. Take a look!

${url}`;
        window.open(
          `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
            shareType === 'vendors' ? storefrontBody : body
          )}`,
          '_blank',
          'noopener,noreferrer'
        );
        break;
      }

      case LINK_SHARING_CHANNELS.FACEBOOK:
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          '_blank',
          'noopener,noreferrer'
        );
        break;

      default:
    }
  };

  return channels.length > 0 ? (
    <div className={styles.linksListContainer} data-testid="LinksList">
      <ul>
        {channels.map((channel) => (
          <li
            key={channel}
            className={styles.listItem}
            data-testid={`list-item-channel-${channel}`}
          >
            <LinkV2
              role="button"
              className={styles.listItemLink}
              onClick={() => linkOnClick(channel)}
              bold
            >
              {LINK_CHANNELS[channel].icon}
              {LINK_CHANNELS[channel].text}
            </LinkV2>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default LinksList;
