import { VendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorWordSingular } from '@zola-helpers/client/dist/es/marketplace/vendorWords';

import { sentenceCase } from '~/util/textUtils';

import styles from './VendorHeader.module.less';

interface VendorHeaderProps {
  image?: string;
  name?: string;
  taxonomyKey?: VendorTaxonomyKey;
  location?: string;
  hed?: string;
}

export const VendorHeader = ({
  image,
  name,
  taxonomyKey,
  location,
  hed = 'Share this vendor with friends and family',
}: VendorHeaderProps) => {
  return (
    <div className={styles.vendorHeader}>
      <div>
        <h3 className={styles.hed}>{hed}</h3>
      </div>
      <div className={styles.vendor}>
        <img src={image} alt="" />
        <div className={styles.info}>
          <p className={styles.name}>{name}</p>
          <div>
            {sentenceCase(getVendorWordSingular(taxonomyKey))} &#8729; {location}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorHeader;
