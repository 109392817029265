import { VendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import type { ButtonVariant } from '@zola/zola-ui/src/components/ButtonV3/types';
import { ShareIcon } from '@zola/zola-ui/src/components/SvgIcons';

import cx from 'classnames';

import LinkSharingModal from '~/components/common/modals/LinkSharingModal/LinkSharingModal';
import { useModal } from '~/contexts/ModalContext';
import { getImageUrl } from '~/util/imageUtils';
import { getAbsoluteUrl } from '~/util/seoUtils';

import styles from './ShareButton.module.less';

type DefaultShareButtonProps = {
  buttonClassName?: string;
  buttonV3Variant?: ButtonVariant;
  className?: string;
  variant?: 'full' | 'compact';
};

type ShareButtonComponentProps = {
  onClick: () => void;
};

type ShareButtonComponent = (props: ShareButtonComponentProps) => JSX.Element;

/**
 * The default share button with various visual options.
 */
export const renderDefaultShareButton = ({
  buttonClassName,
  buttonV3Variant = 'transparent',
  className,
  variant = 'full',
}: DefaultShareButtonProps): ShareButtonComponent => {
  const isFull = variant === 'full';

  return ({ onClick }) => (
    <div className={cx(styles.shareButtonContainer, className)}>
      <ButtonV3
        size="small"
        variant={buttonV3Variant}
        bordered={false}
        onClick={onClick}
        className={cx(
          buttonClassName,
          !isFull && styles.shareButtonCompact,
          buttonV3Variant === 'transparent' && styles.transparentButton
        )}
        fullWidth={isFull}
      >
        <ShareIcon width={20} height={20} className={cx({ [styles.icon]: isFull })} />
        {isFull && 'Share'}
      </ButtonV3>
    </div>
  );
};

export type ShareButtonProps = {
  imageUuid: string;
  slug: string;
  modalHed?: string;
  children: ShareButtonComponent;
} & (
  | {
      modalType: 'vendors';
      taxonomyKey: VendorTaxonomyKey;
      name: string;
      location?: string;
    }
  | { modalType: 'real-weddings' }
);

/**
 * Renders a share button for either real wedding photos or storefronts.
 * This component creates the _behavior_ for the share button, the children
 * component generates the UI and handles the click behavior.
 *
 * @example Renders the classic share button with the default UI
 * <ShareButton {...behaviorProps}>
 *   {renderDefaultShareButton({...uiProps})
 * </ShareButton>
 *
 * @example Renders a custom UI
 * <ShareButton {...behaviorProps}>
 *   {({ onClick }) => ( <LinkV2 onClick={onClick}>Share</LinkV2>)}
 * </ShareButton>
 */
export const ShareButton = (props: ShareButtonProps) => {
  const { imageUuid, modalType, modalHed = 'Share this photo', slug, children } = props;

  const { showCustomModal } = useModal();

  const onButtonClick = () => {
    let shareUrl = getAbsoluteUrl(`/inspiration/photo/${slug}`);
    let shareModalProps = {};
    if (modalType === 'vendors') {
      const { taxonomyKey, name, location } = props;
      shareUrl = getAbsoluteUrl(`/wedding-vendors/${taxonomyKey}/${slug}`);
      shareModalProps = {
        name,
        location,
        taxonomyKey,
      };
    }
    showCustomModal(
      LinkSharingModal,
      {
        hed: modalHed,
        image: getImageUrl(imageUuid, { width: 300, height: 300, quality: 60 }),
        url: shareUrl,
        modalType,
        ...shareModalProps,
      },
      {
        className: 'marketplace__link-sharing-modal-container',
        size: 'lg',
        mobileHalfSheet: true,
      }
    );
  };

  return children({ onClick: onButtonClick });
};
