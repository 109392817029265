import { VendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import cx from 'classnames';

import type { CtaClickedEvent } from '~/util/trackingHelper';

import LinksList from './components/LinksList/LinksList';
import { ModalHeader } from './components/ModalHeader/ModalHeader';
import VendorHeader from './components/ModalHeader/VendorHeader';
import { LINK_SHARING_CHANNELS } from './types/linkSharingChannels';

// styling
import styles from './LinkSharingModal.module.less';

const DEFAULT_CHANNELS = [
  LINK_SHARING_CHANNELS.URL,
  LINK_SHARING_CHANNELS.EMAIL,
  LINK_SHARING_CHANNELS.FACEBOOK,
];

export type LinkSharingModalTypes = 'real-weddings' | 'vendors';

export interface LinkSharingModalProps {
  url: string;
  hed?: string;
  channels?: LINK_SHARING_CHANNELS[];
  image?: string;
  trackingProps?: CtaClickedEvent;
  modalType?: LinkSharingModalTypes;
  name?: string;
  location?: string;
  taxonomyKey?: VendorTaxonomyKey;
}

export const LinkSharingModal = ({
  url,
  hed = 'Share this photo',
  channels = DEFAULT_CHANNELS,
  image,
  trackingProps,
  modalType = 'real-weddings',
  name,
  location,
  taxonomyKey,
}: LinkSharingModalProps): JSX.Element => {
  return (
    <div className={styles.container} data-testid="LinkSharingModal">
      <div
        className={cx(styles.headerContainer, {
          [styles.storefrontHeader]: modalType === 'vendors',
        })}
      >
        {modalType === 'vendors' ? (
          <VendorHeader image={image} name={name} location={location} taxonomyKey={taxonomyKey} />
        ) : (
          <ModalHeader image={image} />
        )}
      </div>

      {hed && <h2 className={styles.hed}>{hed}</h2>}

      <LinksList
        url={url}
        channels={channels}
        trackingProps={trackingProps}
        vendorType={taxonomyKey}
        name={name}
        location={location}
        shareType={modalType}
      />
    </div>
  );
};

export default LinkSharingModal;
